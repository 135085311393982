/**
 * Auth hook for email verification
 */
import { useState } from 'react';
import { REQUESTSTATE } from '../constants/request';
import { verifyEmail } from 'api/auth';

/**
 * Auth hook
 * @returns {Object} Auth utilities
 */
export const useEmailVerification = () => {

    const [requestState, setRequestState] = useState(REQUESTSTATE.IDLE);
    const [error, setError] = useState(null);

    /**
     * Starts the email verification process.
     * @param {string} token - Email verification token.
     * @returns {Promise<object>} User information.
     */
    const startEmailVerification = async (token) => {
        

        setRequestState(REQUESTSTATE.PENDING)
        setError(null);

        try {
            const userProfile = await verifyEmail(token);   // EmailVerification and update token in storage
            setError(null);
            setRequestState(REQUESTSTATE.SUCCESS);
            return userProfile;

        } catch (error) {
            console.log('startEmailVerification', { error })
            const status = error?.response?.status;

            if (status == 422) {
                setError({
                    message: error?.response?.data?.detail,
                    status: 422
                })

            } else if (status == 400) {
                setError({
                    message: error?.response?.data?.detail,
                    status: 400
                })
            }

            else setError({
                message: error?.message,
                status: 500
            })

            setRequestState(REQUESTSTATE.ERROR);

        }

    }




    return {
        error,
        requestState,

        startEmailVerification,
    }
}