// @mui material components
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

function AudioPlayer({ path }) {
	return (
	<div>
		<audio controls controlsList="nodownload" id="voicePlayer">
  			<source src={path} type="audio/wav"/>
		</audio>
	</div>
	);
	
}

AudioPlayer.propTypes = {
	path: PropTypes.string,
};

export default AudioPlayer;