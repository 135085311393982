/**
 * @description This object contains all the possible request states.
 */
const REQUESTSTATE = {
    IDLE: 'IDLE',
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    UNINITIALIZED: 'UNINITIALIZED',
    IN_PROGRESS: 'IN_PROGRESS',
    FAILED: 'FAILED',
    COMPLETED: 'COMPLETED',
    EXPIRED: 'EXPIRED',
}

export {
    REQUESTSTATE,
}