/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useSelector, useDispatch } from "react-redux";

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Soft UI Dashboard PRO React routes
import routes from "routes";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController, setMiniSidenav } from "context";

// Images
import brand from "assets/images/logos/Logo-vocality-RGB_isotipo-color.svg"
import brandtext from "assets/images/logos/Logo-vocality-RGB_color_text.svg"

// Roles
import { ROLES } from 'constants/roles';

// Authentication status enum
import { statusEnum } from "features/auth";

import { refreshToken } from "features/auth/thunks";

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const storeDispatch = useDispatch();

  // Authentication status
  const { status, userProfile } = useSelector((state) => state.auth);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  const isAuthenticatedUser = useMemo(() => status === statusEnum.authenticated && userProfile.is_active, [status, userProfile]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Relogin user if token exists
  useEffect(() => {
    storeDispatch(refreshToken());
  }, [storeDispatch]);

  const getDefaultRoute = (allRoutes) => {
    for (var i = 0; i < allRoutes.length; i++) {
      if (allRoutes[i].default){
        if (userProfile && (allRoutes[i].roles.includes(userProfile.group) || allRoutes[i].roles.includes("all"))){
          return allRoutes[i].route;
        }
      }
    }
  }

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {

      if (isAuthenticatedUser){

        if ((userProfile && !route.roles) || (userProfile && route.roles && (route.roles.includes(userProfile.group) || route.roles.includes("all") || (route.roles.includes(ROLES.SUPERUSER) && userProfile.is_superuser)))){
          if (route.collapse) {
            return getRoutes(route.collapse);
          }
    
          if (route.route) {
            return <Route exact path={route.route} element={route.component} key={route.key} />;
          }
        }
      }
      else{
        if (route.loggedIn == true){
          return null;
        }

        if (route.collapse) {
          return getRoutes(route.collapse);
        }
  
        if (route.route) {
          return <Route exact path={route.route} element={route.component} key={route.key} />;
        }
      }

      return null;
    });

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={brand}
              brandName="VocalityAI"
              brandText={brandtext}
              routes={routes}
              user={userProfile}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to={getDefaultRoute(routes)} />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName="VocalityAI"
            brandText={brandtext}
            routes={routes}
            user={userProfile}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      <Routes> 
        {getRoutes(routes)}
        {
          isAuthenticatedUser ? 
          (
            <Route path="*" element={<Navigate to={getDefaultRoute(routes)} />} />
          ) : 
          (
            <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
          )
        }
      </Routes>
    </ThemeProvider>
  );
}
