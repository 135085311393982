import { createBaseAxiosInstance } from "./client";


/**
 * Create a new setup intent
 */
async function createSetupIntent( customerID ){
    const apiClient = createBaseAxiosInstance();

    const response = await apiClient.post(
        'stripe/intents/setup/create',
        {customer: customerID}
    );

    const { data } = response;
    return data;
}

/**
 * Cancel a setup intent
 * @param {string} setupIntentId 
 */
async function cancelSetupIntent(setupIntentId){
    const apiClient = createBaseAxiosInstance();

    const response = await apiClient.delete(
        `stripe/intents/setup/${setupIntentId}/cancel`
    );

    const { data } = response;
    return data;
}

export {
    createSetupIntent,
    cancelSetupIntent
}