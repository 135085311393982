/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";

// Authentication layout components
import Footer from "layouts/authentication/components/Footer";

// Images
import error404 from "assets/images/shapes/vocalityai.svg";

function Error404() {
  const { d1, d3, d4, d5 } = typography;

  return (
    <PageLayout white>
        <Grid container>
          <Grid item xs={11} sm={8} md={6} lg={4} xl={3} sx={{ mx: "auto" }}>
            <SoftBox display="flex" flexDirection="column" justifyContent="center" height="100vh">
              <SoftBox
                fontSize={{ xs: d5.fontSize, sm: d4.fontSize, md: d3.fontSize, lg: d1.fontSize }}
                lineHeight={1.2}
              >
                <SoftTypography variant="inherit" color="error" textGradient fontWeight="bold">
                  Error 404
                </SoftTypography>
              </SoftBox>
              <SoftTypography variant="h2" color="dark" textGradient fontWeight="bold">
                ¿Qué ha pasado? ¡No hemos encontrado la página!
              </SoftTypography>
              <SoftBox mt={1} mb={2}>
                <SoftTypography variant="body1" color="text">
                  Mientras tratamos de arreglar este problema, puedes volver a la página inicial.
                </SoftTypography>
              </SoftBox>
              <SoftBox mt={4} mb={2}>
                <SoftButton component={Link} to="/" variant="gradient" color="secondary">
                  Ir a inicio
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <SoftBox
              display={{ xs: "none", lg: "flex" }}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="calc(100% - 2rem)"
              height="calc(100vh - 2rem)"
              position="relative"
              borderRadius="lg"
              textAlign="center"
              variant="gradient"
              m={2}
              px={13}
              sx={{ overflow: "hidden" }}
            >
              <SoftBox
                component="img"
                src={error404}
                alt="error-404"
                width="120rem"
                position="absolute"
                topl={0}
                left={0}
                opacity={1}
              />
            </SoftBox>
          </Grid>
        </Grid>
    </PageLayout>
  );
}

export default Error404;


