// @mui material components
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';
import IconButton from "@mui/material/IconButton";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

import Swal from "sweetalert2";

const CustomWidthTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
  ))({
	[`& .${tooltipClasses.tooltip}`]: {
	  maxWidth: 500,
	  backgroundColor: '#556877',
	},
	[`& .${tooltipClasses.arrow}`]: {
		maxWidth: 500,
		color: '#556877',
	  },
  });

function InfoButton({ text, modal }) {
	const showAlert = () => {
		Swal.fire({html: text, icon: "info"});
	};
	if (modal){
		return (
			<IconButton size="small" onClick={showAlert}>
				<Icon sx={{ marginLeft: 1 }} color="secondary">info</Icon>
			</IconButton>
		);	
	} else {
		return (
			<CustomWidthTooltip title={text}>
				<Icon sx={{ marginLeft: 1 }} color="secondary">info</Icon>
			</CustomWidthTooltip>
		);
	}
}

InfoButton.propTypes = {
	text: PropTypes.string,
	modal: PropTypes.bool,
};

export default InfoButton;