/**
 * @description This object contains all the possible roles
 */
const ROLES = {
    ADMIN: 'ADMIN',
    PRODUCTOR: 'PRODUCTOR',
    TECHNICIAN: 'TECHNICIAN',
    SPEAKER: 'SPEAKER',
    SUPERUSER: 'SUPERUSER',
}

export {
    ROLES,
}