/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "layouts/authentication/reset-password/schemas/form";

const {
  formField: {
    password,
    repeatPassword
  },
} = checkout;

const initialValues = {
  [password.name]: "",
  [repeatPassword.name]: "",
};

export default initialValues;
