/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Authentication layout components
import Footer from "layouts/authentication/components/Footer";


function StepperCardLayout({ children }) {
  return (
    <PageLayout>
      <SoftBox mb={{ xs: -36, lg: -38 }} px={1} width="calc(100% - 2rem)" mx="auto">
        <Grid justifyContent="center">
            {children}
        </Grid>
        <Footer light/>
      </SoftBox>
      <SoftBox
        id="greenbox"
        width="calc(100% - 2rem)"
        minHeight="50vh"
        borderRadius="lg"
        bgColor="primary"
        mx={2}
        my={2}
        pt={6}
        pb={28}
        sx={{
          backgroundColor: 'secondary',
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid container spacing={3} justifyContent="center" sx={{ textAlign: "center" }}>
        </Grid>
      </SoftBox>
    </PageLayout>
  );
}

// Typechecking props for the StepperCardLayout
StepperCardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StepperCardLayout;
