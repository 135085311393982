/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Dropzone components
import Dropzone from "dropzone";

// Dropzone styles
import "dropzone/dist/dropzone.css";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Custom styles for the SoftDropzone
import SoftDropzoneRoot from "components/SoftDropzone/SoftDropzoneRoot";

//XLSX
import * as XLSX from 'xlsx/xlsx.mjs';

function SoftDropzone({ options, name, textZone, dictionary }) {
  const dropzoneRef = useRef();

  // PROCESSING EXCEL FILES TO JSON
  const parseExcel = (file, handler) => {
      var reader = new FileReader();
      reader.onload = function (e) {
          var data = e.target.result;
          var workbook = XLSX.read(data, {
              type: 'binary'
          });
          workbook.SheetNames.forEach(function (sheetName) {
              // Here is your object
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[
                  sheetName]);
              var json_object = JSON.stringify(XL_row_object);
              handler(JSON.parse(json_object));
          })
      };

      reader.onerror = function (ex) {
          console.log(ex);
      };

      reader.readAsBinaryString(file);
  };
  //--------------------------------------------------


  useEffect(() => {
    Dropzone.autoDiscover = false;

    function createDropzone() {
      return new Dropzone(dropzoneRef.current, { ...options, 
        dictDefaultMessage: "Suelta aquí un fichero para subirlo", //Drop files here to upload
        dictFallbackMessage: "Tu navegador no admite la carga de archivos mediante arrastrar y soltar.", //Your browser does not support drag'n'drop file uploads.
        dictFallbackText: "Utilice el siguiente formulario para cargar sus archivos.", //Please use the fallback form below to upload your files.
        dictFileTooBig: "¡El fichero es demasiado grande!", //File is too big!
        dictInvalidFileType: "No puedes subir ficheros de este tipo.", //You can't upload files of this type.
        dictResponseError: "El servidor ha respondido con código {{statusCode}}.", //Server responded with {{statusCode}} code.
        dictCancelUpload: "Cancelar subida", //Cancel upload
        dictCancelUploadConfirmation: "¿Estás seguro que quieres cancelar la subida?", //Are you sure you want to cancel this upload?
        dictRemoveFile: "Eliminar archivo", //Remove file
        dictMaxFilesExceeded: "No puedes subir más ficheros." //You can not upload any more files.
          }).on("complete", function(file) {
          file.text().then(function(response) {
            if (textZone){
              textZone.handler.handleTextDropped(response);
            }
            else if (dictionary){
              // Do nothing
              dictionary.setDictionaryText.setDictionaryText("Cargado el diccionario " + file.name);
              parseExcel(file, dictionary.addHandler.handleAddDictionary);
            }
          })
        }).on('addedfile', function(file) {
          while ( this.files.length > this.options.maxFiles ) this.removeFile(this.files[0])
        });
    }

    function removeDropzone() {
      if (Dropzone.instances.length > 0) Dropzone.instances.forEach((dz) => dz.destroy());
    }

    createDropzone();

    return () => removeDropzone();
  }, [options]);

  return (
    <SoftDropzoneRoot
      component="form"
      action="/file-upload"
      ref={dropzoneRef}
      className="form-control dropzone"
    >
      <SoftBox className="fallback">
        <SoftBox component="input" name={name ? name : "file"} type="file" />
      </SoftBox>
    </SoftDropzoneRoot>
  );
}

// Typechecking props for the SoftDropzone
SoftDropzone.propTypes = {
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string,
  textZone: PropTypes.objectOf(PropTypes.any),
  dictionary: PropTypes.objectOf(PropTypes.any)
};

export default SoftDropzone;
