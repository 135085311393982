/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

// Soft UI Dashboard PRO React example components
import NotificationItem from "template/examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard PRO React context
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
} from "context";

import { useLogin } from "hooks/useLogin";

// Language flags
import ES from "assets/images/icons/flags/es.png";
import EK from "assets/images/icons/flags/ek.png";
import GB from "assets/images/icons/flags/gb.png";
import DE from "assets/images/icons/flags/de.png";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
  const route = useLocation().pathname.split("/").slice(1);

  //Menu
  const [langMenu, setLangMenu] = useState(null);
  const openLangMenu = (event) => setLangMenu(event.currentTarget);
  const closeLangMenu = () => setLangMenu(null);

  // Hook to handle the logout
  const { logoutUser } = useLogin();

  //Title object
  const titles = {
    default : "Generación de voz",
    history : "Histórico"
  };

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleLogout = () => logoutUser();

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SoftBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <SoftBox mr={{ xs: 0, xl: 6 }}> 
            <SoftTypography
            fontWeight="bold"
            textTransform="capitalize"
            variant="h6"
            color={light ? "white" : "dark"}
            noWrap
            >
              {titles[route[route.length - 1].replace("-", " ")]}
            </SoftTypography>
          </SoftBox>
          <Icon fontSize="medium" sx={navbarDesktopMenu} onClick={handleMiniSidenav}>
            {miniSidenav ? "menu_open" : "menu"}
          </Icon>
        </SoftBox>
        {isMini ? null : (
          <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
            {/* <SoftBox color={light ? "white" : "inherit"}>
                <IconButton sx={navbarIconButton} size="large" onClick={openLangMenu}>
                  <Icon
                    sx={({ palette: { dark, white } }) => ({
                      color: light ? white.main : dark.main,
                    })}
                  >
                    language
                  </Icon>
                </IconButton>
                <Menu
                  anchorEl={langMenu}
                  anchorOrigin={{ vertical: "top", horizontal: "left" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  open={Boolean(langMenu)}
                  onClose={closeLangMenu}
                  keepMounted
                >
                  <MenuItem selected onClick={closeLangMenu}><SoftBox component="img" src={ES} alt="master card" width="10%" mr={2} />Español</MenuItem>
                  <MenuItem onClick={closeLangMenu}><SoftBox component="img" src={GB} alt="master card" width="10%" mr={2} />English</MenuItem>
                  <MenuItem onClick={closeLangMenu}><SoftBox component="img" src={DE} alt="master card" width="10%" mr={2} />Deutsch</MenuItem>
                  <MenuItem onClick={closeLangMenu}><SoftBox component="img" src={EK} alt="master card" width="10%" mr={2} />Euskera</MenuItem>
                </Menu>
            </SoftBox> */}
            <SoftBox display="flex" justifyContent="space-between" color={light ? "white" : "inherit"}>
                <IconButton
                    size="small"
                    color="inherit"
                    sx={navbarMobileMenu}
                    onClick={handleMiniSidenav}
                  >
                  <Icon className={light ? "text-white" : "text-dark"}>
                    {miniSidenav ? "menu_open" : "menu"}
                  </Icon>
                </IconButton>
                <IconButton 
                  sx={navbarIconButton} 
                  size="medium" 
                  onClick={handleLogout}
                >
                  <Icon
                    sx={({ palette: { dark, white } }) => ({
                      color: light ? white.main : dark.main,
                    })}
                  >
                    logout
                  </Icon>
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color={light ? "white" : "dark"}
                  >
                    Cerrar sesión
                  </SoftTypography>
                </IconButton>
            </SoftBox>
          </SoftBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
