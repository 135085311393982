
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { api } from '../app/baseQuery'

const inferenceApi = api.injectEndpoints({
    tagTypes: ['Inference'],
    endpoints: (build) => ({

        // Get all inference requests
        getInferences: build.query({
            query: () => 'db/inference-requests/read-all',
        }),

        // Get inference requests by user
        getInferenceByUser: build.query({
            query: () => 'db/inference-requests/read',
        }),

        // Get inference requests by inference id
        getInferenceByInferenceId: build.query({
            query: (inferenceId) => `db/inference-requests/read/${inferenceId}`,
        }),

        // Get inference requests by customer id
        getInferenceByCustomerId: build.query({
            query: (customerId) => `db/inference-requests/read-by-customer/${customerId}`,
        }),

        // Create inference request
        createInferenceRequest: build.mutation({
            query: (data) => {

                const formBody = new URLSearchParams(data).toString()

                return {
                    url: 'ai/inference/request',
                    method: 'POST',
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    body: formBody,
                }
            },
        }),

    }),
    overrideExisting: false,
})

export const { 
    useGetInferencesQuery,
    useGetInferenceByUserQuery,
    useGetInferenceByInferenceIdQuery,
    useGetInferenceByCustomerIdQuery,
    useCreateInferenceRequestMutation, 
} = inferenceApi