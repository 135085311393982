/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Authentication layout components
import Footer from "layouts/authentication/components/Footer";

//page components
import FormField from "layouts/authentication/components/FormField";

// Change password schemas for form and form feilds
import validations from "layouts/authentication/reset-password/schemas/validations";
import form from "layouts/authentication/reset-password/schemas/form";
import initialValues from "layouts/authentication/reset-password/schemas/initialValues";


function ResetPassword() {

  const { formId, formField } = form;
  const { password, repeatPassword } = formField;

  const navigate = useNavigate();

  const handleSubmit = async (values, actions) => {
    navigate("/authentication/sign-in");
  };

  return (
    <PageLayout background="light">
      <Grid container spacing={3} justifyContent="center" sx={{ minHeight: "75vh" }}>
        <Grid item xs={10} md={6} lg={4}>
          <SoftBox mt={32} mb={3} px={{ xs: 0, lg: 2 }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validations}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card>
                    <SoftBox pt={3} px={3} pb={1} textAlign="center">
                      <SoftTypography variant="h4" fontWeight="bold" textGradient>
                        Change password
                      </SoftTypography>
                      <SoftTypography variant="body2" color="text">
                        Please set the new password for your account
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox p={3}>
                      <SoftBox>
                        <SoftBox mb={2}>
                        <FormField
                            type={password.type}
                            label={password.label}
                            name={password.name}
                            value={values.password}
                            placeholder={password.placeholder}
                            error={errors.password && touched.password}
                            success={values.password.length > 0 && !errors.password}
                            inputProps={{ autoComplete: "" }}
                          />
                        </SoftBox>
                        <SoftBox mb={2}>
                        <FormField
                          type={repeatPassword.type}
                          label={repeatPassword.label}
                          name={repeatPassword.name}
                          value={values.repeatPassword}
                          placeholder={repeatPassword.placeholder}
                          error={errors.repeatPassword && touched.repeatPassword}
                          success={values.repeatPassword.length > 0 && !errors.repeatPassword}
                          inputProps={{ autoComplete: "" }}
                        />
                        </SoftBox>
                        <SoftBox mt={5} mb={1}>
                          <SoftButton disabled={isSubmitting} type="submit" variant="contained" color="dark" size="large" fullWidth>
                            send
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </SoftBox>
        </Grid>
      </Grid>
      <Footer />
    </PageLayout>
  );
}

export default ResetPassword;


