/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// NewUser page components
import FormField from "layouts/authentication/components/FormField";
import FormSelect from "layouts/authentication/components/FormSelect";

function UserProfileInputs({ formFields, values, errors, touched }) {
  const { 
    firstName: firstNameF, 
    lastName: lastNameF, 
    company: companyF, 
    email: emailF, 
    password: passwordF, 
    repeatPassword: repeatPasswordF,
    taxIDType: taxIDTypeF,
    taxID: taxIDF, 
} = formFields;
  const {
    firstName: firstNameV,
    lastName: lastNameV,
    company: companyV,
    email: emailV,
    password: passwordV,
    repeatPassword: repeatPasswordV,
    taxIDType: taxIDTypeV,
    taxID: taxIDV,
  } = values;

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h4" fontWeight="bold">
          Datos personales
        </SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          Información personal y de la empresa
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={firstNameF.type}
              label={firstNameF.label}
              name={firstNameF.name}
              value={values.firstName}
              placeholder={firstNameF.placeholder}
              error={errors.firstName && touched.firstName}
              success={firstNameV.length > 0 && !errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={lastNameF.type}
              label={lastNameF.label}
              name={lastNameF.name}
              value={lastNameV}
              placeholder={lastNameF.placeholder}
              error={errors.lastName && touched.lastName}
              success={lastNameV.length > 0 && !errors.lastName}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={companyF.type}
              label={companyF.label}
              name={companyF.name}
              value={companyV}
              placeholder={companyF.placeholder}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={emailF.type}
              label={emailF.label}
              name={emailF.name}
              value={emailV}
              placeholder={emailF.placeholder}
              error={errors.email && touched.email}
              success={emailV.length > 0 && !errors.email}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={passwordF.type}
              label={passwordF.label}
              name={passwordF.name}
              value={passwordV}
              placeholder={passwordF.placeholder}
              error={errors.password && touched.password}
              success={passwordV.length > 0 && !errors.password}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={repeatPasswordF.type}
              label={repeatPasswordF.label}
              name={repeatPasswordF.name}
              value={repeatPasswordV}
              placeholder={repeatPasswordF.placeholder}
              error={errors.repeatPassword && touched.repeatPassword}
              success={repeatPasswordV.length > 0 && !errors.repeatPassword}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormSelect
              label={taxIDTypeF.label}
              name={taxIDTypeF.name}
              value={taxIDTypeV}
              defaultValue={taxIDTypeV}
              options={taxIDTypeF.options}
              placeholder={taxIDTypeF.placeholder}
              error={errors.taxIDType && touched.taxIDType}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={taxIDF.type}
              label={taxIDF.label}
              name={taxIDF.name}
              value={taxIDV}
              placeholder={taxIDF.placeholder}
              error={errors.taxID && touched.taxID}
              success={taxIDV.length > 0 && !errors.taxID}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
UserProfileInputs.propTypes = {
    formFields: PropTypes.object,
    values: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.object,
};

export default UserProfileInputs;
