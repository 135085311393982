import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Formik - https://formik.org/
import { Formik, Form } from "formik";

// @mui material components
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftDropzone from "components/SoftDropzone";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftBadgeDot from "components/SoftBadgeDot";

// Custom hooks
import { useRegisterSpeaker } from "hooks/useRegisterSpeaker";

// Formik layout schemas for form and form fields
import { 
    formID,
    formFields,
    initialSpeakerProfileValues,
    speakerProfileValidations
} from "./schema";
// Custom components
import SpeakerProfileInputs from "./inputs";

function SpeakerUserForm({ title, language, handleClose, open }) {

	const {
        error,
        requestState,
        registeredSpeaker,
		handleSpeakerProfileInvite,
    } = useRegisterSpeaker();

	const handleSubmit = (values, actions) => {
		handleSpeakerProfileInvite(title, language, values, actions)
		handleClose()
	}

	const handleCancel = (event) => {
		event.preventDefault()
		handleClose()
	}

	// Render

	return (
		<div style={{ display: "flex" }}>
			<Dialog
				open={open}
				onClose={handleClose}
				fullWidth
  				maxWidth="sm"
			>
				<DialogTitle>
					Crear usuario para voz {title}
				</DialogTitle>
				<DialogContent>
					<Formik
						initialValues={initialSpeakerProfileValues}
						validationSchema={speakerProfileValidations}
						onSubmit={handleSubmit}
					>
						{({ values, errors, touched, isSubmitting }) => (
							<Form id={formID} autoComplete="off">
								<SoftBox>
									<SpeakerProfileInputs 
										formFields={formFields}   
										values={values}
										errors={errors}
										touched={touched} 
									/>
									<SoftBox mt={2}
										width="100%"
										display="flex"
										justifyContent="space-between">
										<SoftButton autoFocus color="secondary" onClick={handleCancel}>
											Cancelar
										</SoftButton>
										<SoftButton type="submit" color="primary" autoFocus>
											Enviar correo
										</SoftButton>
									</SoftBox>
								</SoftBox>
							</Form>
						)}
					</Formik>
				</DialogContent>
			</Dialog>
		</div>
	);
}

// Setting default values for the props of DataTable
SpeakerUserForm.defaultProps = {
	title: "",
};
	
	// Typechecking props for the DataTable
SpeakerUserForm.propTypes = {
	title: PropTypes.string.isRequired,
	language: PropTypes.string.isRequired,
	handleClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
};

export default SpeakerUserForm;