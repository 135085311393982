/**
 * Auth hook for registering
 */

// sweetalert2 components
import Swal from "sweetalert2";

import { useState } from 'react';

import { REQUESTSTATE } from '../constants/request';
import { createSpeakerUser, createSpeakerInvite } from "api/auth";

/**
 * Auth hook for registering a user as a speaker
 * @returns {Object} Auth utilities
 */
export const useRegisterSpeaker = () => {

    const [registeredSpeaker, setRegisteredSpeaker] = useState(null); 
    const [requestState, setRequestState] = useState(REQUESTSTATE.IDLE);
    const [error, setError] = useState(null);

    /**
    * Handles the speaker profile invite
    * @param {*} voice - User voice name
    * @param {*} language - User language
    * @param {*} values - User profile values
    * @param {*} actions - Formik actions
    */
    const handleSpeakerProfileInvite = async (voice, language, values, actions) => {

        setRequestState(REQUESTSTATE.PENDING)
        setError(null);

        try {
            const newSpeaker = await createSpeakerInvite({
                email: values.email,
                voice: voice,
                language: language,
                });
            setError(null);
            setRequestState(REQUESTSTATE.SUCCESS);

            Swal.fire("¡Todo listo!", "Enviaremos un correo de verificación al usuario para establecer el nombre y contraseña.", "success");

        } catch (error) {
            Swal.fire("¡Error!", error?.response?.data?.detail, "error");
            const status = error?.response?.status;

            if (status == 422) {
                setError({
                    message: error?.response?.data?.detail,
                    status: 422
                })

            } else if (status == 400) {
                setError({
                    message: error?.response?.data?.detail,
                    status: 400
                })
            }

            else setError({
                message: error?.message,
                status: 500
            })

            setRequestState(REQUESTSTATE.ERROR);

        } finally {
            actions.setSubmitting(false);
        }
    }

    /**
     * Handles the speaker profile submit
     * @param {*} values - User profile values
     * @param {*} actions - Formik actions
     */
    const handleSpeakerProfileSubmit = async (email, token, values, actions) => {

        setRequestState(REQUESTSTATE.PENDING)
        setError(null);

        try {
            const newSpeaker = await createSpeakerUser({
                email: email,
                password: values.password,
                name: values.firstName,
                surname: values.lastName,
                token: token
                });
            setError(null);
            setRequestState(REQUESTSTATE.SUCCESS);

            Swal.fire("Locutor creado correctamente", "Ya puedes acceder a la web con tu usuario y contraseña.", "success");
            setRegisteredSpeaker(newSpeaker);

        } catch (error) {
            Swal.fire("¡Error!", error?.response?.data?.detail, "error");
            const status = error?.response?.status;

            if (status == 422) {
                setError({
                    message: error?.response?.data?.detail,
                    status: 422
                })

            } else if (status == 400) {
                setError({
                    message: error?.response?.data?.detail,
                    status: 400
                })
            }

            else setError({
                message: error?.message,
                status: 500
            })

            setRequestState(REQUESTSTATE.ERROR);

        } finally {
            actions.setSubmitting(false);
        }
    }

    return {
        error,
        requestState,
        registeredSpeaker,
        handleSpeakerProfileInvite,
        handleSpeakerProfileSubmit
    }
}