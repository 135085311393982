/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useSearchParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";

// Images
import verified from "assets/images/logos/Logo-vocality-RGB_isotipo-color.svg";
import { useEmailVerification } from "hooks/useEmailVerification";
import { useEffect } from "react";

function VerifyUser() {
  const { d1, d3, d4, d5 } = typography;

  const {  startEmailVerification, requestState, error } = useEmailVerification();
  const [ searchParams ] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      startEmailVerification(token);
    }
  }, [searchParams]);

  return (
    <PageLayout white>
      <SoftBox my={24} height="calc(100vh - 24rem)">
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Grid item xs={11} sm={9} container alignItems="center">
            <Grid item xs={12} lg={6}>
              <SoftBox
                fontSize={{ xs: d5.fontSize, sm: d4.fontSize, md: d3.fontSize, lg: d1.fontSize }}
                lineHeight={1.2}
              >
                <SoftTypography variant="inherit" color="success" textGradient fontWeight="bold">
                  ¡Usuario verificado!
                </SoftTypography>
              </SoftBox>
              <SoftTypography variant="h2" color="dark" textGradient fontWeight="bold">
                Tu usuario ha sido verificado en VocalityAI.
              </SoftTypography>
              <SoftBox mt={1} mb={2}>
                <SoftTypography variant="body1" color="text">
                  Vuelve a la página inicial para entrar a la App.
                </SoftTypography>
              </SoftBox>
              <SoftBox mt={4} mb={2}>
                <SoftButton component={Link} to="/authentication/sign-in" variant="gradient" color="success">
                  Ir a inicio
                </SoftButton>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={1}>
            </Grid>
            <Grid item xs={12} lg={5}>
              <SoftBox component="img" src={verified} alt="verified" width="70%" />
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
    </PageLayout>
  );
}

export default VerifyUser;
