const registerSteps = [
    {
        label: "Datos de usuario",
    },
    {
        label: "Datos de facturación",
    },
]

export {
    registerSteps
}