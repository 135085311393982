import { createBaseAxiosInstance, createAuthenticatedAxiosInstance } from "../api/client";

/**
 * Makes a POST request to the server to get the access token. It stores the token in the local storage.
 * @typedef {object} LoginResponse
 * @property {string} access_token - JWT token.
 * @property {string} token_type - Token type (Bearer).
 *
 * @typedef {object} Credentials
 * @property {string} credentials.username - User's username (email).
 * @property {string} credentials.password - User's password.
 *
 * @param {Credentials} credentials
 * @returns {Promise<LoginResponse>}
 */
async function getAccessToken(credentials) {
    const apiClient = createBaseAxiosInstance();
    const encodedCredentials = new URLSearchParams(credentials);

    const response = await apiClient({
        method: "post",
        url: "/auth/jwt/login",
        data: encodedCredentials.toString(),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });

    const { data } = response;

    localStorage.setItem("token", data.access_token);

    return data;
}

/**
 * Gets the user information.
 * @returns {Promise<object>} User information.
 */
async function getUserInfo() {
    const apiClient = createAuthenticatedAxiosInstance();

    const response = await apiClient.get("/auth/users/me");

    const { data } = response;

    return data;
}

/**
 * Refreshes the user token.
 * @returns {Promise<object>} User token.
 */
async function getNewToken() {
    const apiClient = createAuthenticatedAxiosInstance();

    const response = await apiClient.get("/auth/token/refresh");

    const { data } = response;

    return data;
}

/**
 * Creates a new admin user.
 * @param {object} userInfo
 * @param {string} userInfo.email - User's email.
 * @param {string} userInfo.password - User's password.
 * @param {string} userInfo.name - User's name.
 * @param {string} userInfo.surname - User's surname.
 * @param {string} userInfo.group_id - User's group id (1,2,3)
 * @param {string} userInfo.customer_name - Stripe customer name.
 * @returns {Promise<object>} User information.
 */
async function createAdminUser(userInfo) {
    const apiClient = createBaseAxiosInstance();
    const response = await apiClient.post("/auth/register/admin", userInfo);
    const { data } = response;
    return data;
}

async function createSpeakerInvite(speakerInfo) {
    const apiClient = createAuthenticatedAxiosInstance();
    const response = await apiClient.post("/auth/register/speaker/send-invitation-token", speakerInfo);
    const { data } = response;
    return data;
}

async function createSpeakerUser(speakerInfo) {
    const apiClient = createBaseAxiosInstance();
    const response = await apiClient.post("/auth/register/speaker", speakerInfo);
    const { data } = response;
    return data;
}

/**
 * Verify user's email using the token.
 * @param {string} token - Email verification token.
 * @returns {Promise<object>} User information.
 */
async function verifyEmail(token) {
    const apiClient = createBaseAxiosInstance();
    const response = await apiClient.post("/auth/verify", { token });
    const { data } = response;
    return data;
}

export { getAccessToken, getUserInfo, getNewToken, createAdminUser, createSpeakerInvite, createSpeakerUser, verifyEmail };
