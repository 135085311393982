/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// formik components
import { ErrorMessage, Field, Form, Formik } from "formik";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Terms page components
import LegalText from "layouts/authentication/components/Terms/LegalText";
import { Card } from "@mui/material";

import { formFields } from "./schema";
const { termsOfService } = formFields;

function TermsOfService() {

    return (
        <SoftBox mt={2}>
            {/* Header */}
            <SoftTypography variant="h4" fontWeight="bold">
                Términos y condiciones
            </SoftTypography>
            <SoftTypography variant="button" fontWeight="regular" color="text">
                Por favor, lea con cuidado nuestras políticas e indica que estás de acuerdo.
            </SoftTypography>

            {/* Legal text */}
            <SoftBox mt={1.625}>
                <LegalText />
            </SoftBox>

            {/* Checkbox */}
            <SoftBox>
                <Field type={termsOfService.type} name={termsOfService.name} />
                <SoftTypography
                    variant="button"
                    fontWeight="bold"
                    sx={{ cursor: "pointer", userSelect: "none" }}
                >
                    &nbsp;&nbsp;Estoy de acuerdo con los Términos y Condiciones&nbsp;
                </SoftTypography>
            </SoftBox>
            <SoftBox display="flex" justifyContent="flex-start" mb={1}>
                <SoftTypography component="div" variant="caption" color="error">
                    <ErrorMessage name={termsOfService.name} />
                </SoftTypography>
            </SoftBox>
        </SoftBox>
    );
}

export { TermsOfService };
