/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useSearchParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Custom hooks
import { useRegisterSpeaker } from "hooks/useRegisterSpeaker";

// Custom components
import StepperCardLayout from "layouts/authentication/components/StepperCardLayout";
import { SpeakerProfileForm } from "../components/register/SpeakerProfileForm";

// services
import { useGetDecodedInviteQuery } from "services/speaker";

const RegisterSpeakerPage = () => {

    const [searchParams, setSearchParams] = useSearchParams();

    const { data, isError, isLoading } = useGetDecodedInviteQuery(searchParams.get("token"));
    
    const {
        error,
        requestState,
        registeredSpeaker,
        handleSpeakerProfileSubmit,
    } = useRegisterSpeaker();

    const handleSubmit = (values, actions) => {
		handleSpeakerProfileSubmit(data.email, searchParams.get("token"), values, actions)
	}

    return (
        <StepperCardLayout>
            <SoftBox py={3}>
                <Grid container justifyContent="center" sx={{ height: "100%" }}>
                    {!registeredSpeaker && <SpeakerProfileForm handleSubmit={handleSubmit} />}
                    {registeredSpeaker && <Card sx={{ height: "100%" }}>
                     <SoftBox p={10}>
                        <SoftBox>
                            <SoftTypography variant="inherit" color="success" textGradient fontWeight="bold">
                            ¡Usuario creado!
                            </SoftTypography>
                        </SoftBox>
                        <SoftTypography variant="h2" color="dark" textGradient fontWeight="bold">
                            Ahora puedes entrar a VocalityAI con tu email y contraseña.
                        </SoftTypography>
                        <SoftBox mt={1} mb={2}>
                            <SoftTypography variant="body1" color="text">
                            Vuelve a la página inicial para entrar a la App.
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox mt={4} mb={2}>
                            <SoftButton component={Link} to="/authentication/sign-in" variant="gradient" color="success">
                            Ir a inicio
                            </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </Card>}
                </Grid>
            </SoftBox>
        </StepperCardLayout>
    );
};

// typechecking props for Register
RegisterSpeakerPage.propTypes = {
};

export default RegisterSpeakerPage;
