import PropTypes from 'prop-types';
import { createContext } from 'react';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import { defaultOptions } from '../constants/stripe';
import { useStripeSetupIntent } from '../hooks/useStripeSetupIntent';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const SetupIntentContext = createContext(null);

function StripeSetupIntentProvider({ customerID, options = {}, children}) {

    const stripeOptions = {...defaultOptions, ...options};

    const {
        setupIntent,
        errorMessage,
    } = useStripeSetupIntent({customerID});
    
    if (setupIntent === null) {
        return <p>Loading stripe elements</p>;
    }

    return (
        <Elements 
            stripe={stripePromise} 
            options={{...stripeOptions, clientSecret: setupIntent?.client_secret}}
        >
            <SetupIntentContext.Provider 
                value={{setupIntent, errorMessage, customerID}}
            >
                {children}
            </SetupIntentContext.Provider>
        </Elements>
    );
}

StripeSetupIntentProvider.propTypes = {
    customerID: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    options: PropTypes.object,
}

export {
    SetupIntentContext,
    StripeSetupIntentProvider
}
