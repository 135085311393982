import PropTypes from "prop-types";
import { useState, useMemo } from "react";

// Formik - https://formik.org/
import { Formik, Form } from "formik";

// react-router-dom components
import { useNavigate, Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import Loading from "examples/Animations/Loading";

// Formik layout schemas for form and form fields
import { 
    formID,
    formFields,
    initialUserProfileValues,
    userProfileValidations
} from "./schema";

// Custom components
import UserProfileInputs from "./inputs";
import { TermsOfService } from "./TermsOfService";

function UserProfileForm({ handleSubmit }) {

    return (
        <Formik
            initialValues={initialUserProfileValues}
            validationSchema={userProfileValidations}
            onSubmit={handleSubmit}
        >
            {({ values, errors, touched, isSubmitting }) => (
                <Form id={formID} autoComplete="off">
                    <Card sx={{ height: "100%" }}>
                        <SoftBox p={2}>
                            <SoftBox>

                                <UserProfileInputs 
                                    formFields={formFields}   
                                    values={values}
                                    errors={errors}
                                    touched={touched} 
                                />
                                <Divider />
                                <TermsOfService />

                                <SoftBox
                                    mt={2}
                                    width="100%"
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    {/* Back to sign in button */}
                                    <SoftButton
                                        variant="contained"
                                        color="dark"
                                        component={Link}
                                        to="/authentication/sign-in"
                                    >
                                        Vuelta a iniciar sesión
                                    </SoftButton>

                                    {/* Loading */}
                                    {isSubmitting && <Loading />}

                                    {/* Next step button */}
                                    <SoftButton
                                        type="submit"
                                        variant="gradient"
                                        color="secondary"
                                        disabled={isSubmitting}
                                    >
                                        Siguiente
                                    </SoftButton>

                                </SoftBox>

                            </SoftBox>
                        </SoftBox>
                    </Card>
                </Form>
            )}
        </Formik>
    );
}

UserProfileForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

export { UserProfileForm };
