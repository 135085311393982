// @mui material components
import Divider from "@mui/material/Divider";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function LegalText(){
	return (
	<SoftBox position="relative" py={0.25}>
		<Accordion>
			<AccordionSummary
			expandIcon={<ExpandMoreIcon />}
			aria-controls="panel1-content"
			id="panel1-header"
			>
			<SoftTypography>Política de Privacidad</SoftTypography>
			</AccordionSummary>
			<AccordionDetails>
			<SoftTypography
				variant="body2"
				component='span'
			>
				<p>
				El Reglamento <b>(UE) 2016/679 de protección de datos de carácter personal</b>, establece un nuevo enfoque para el control y el tratamiento de los datos de carácter personal. Hemos revisado y adaptado nuestra política de privacidad a los nuevos requisitos y queremos ofrecer mayor información y transparencia en relación con el tratamiento de los datos personales. 
				Vocality.ai es <b>responsable del tratamiento</b> de los datos de carácter personal de sus usuarios y clientes, así como de los datos que puedan ser recabados a través del sitio web. 
				<br/><br/>
				Vocality.ai informa a los usuarios del sitio web sobre su política respecto del tratamiento y protección de los datos de carácter personal de los usuarios y clientes que puedan ser recabados por la navegación o contratación de servicios a través de su sitio web. 
				<br/><br/>
				<b>Finalidad del tratamiento de los datos</b><br/>
				Sus datos de carácter personal se recaban con la finalidad de prestar y mantener los servicios acordados contractualmente así como poder informarle sobre los nuevos productos y servicios. 
				El tratamiento de los datos que nos facilite a través de los formularios de la web <i>www.vocality.ai</i>, se regirá por esta misma política de privacidad. 
				Las operaciones, gestiones y procedimientos técnicos que se realicen de forma automatizada o no automatizada y que posibiliten la recogida, el almacenamiento, la modificación, la transferencia y otras acciones sobre datos de carácter personal, tienen la consideración de tratamiento de datos personales. 
				Vocality procederá a tratar los datos de carácter personal de manera lícita, leal, transparente, adecuada, pertinente, limitada, exacta y actualizada. 
				<br/><br/>
				<b>Datos recabados para la prestación de un servicio </b><br/>
				Los datos de carácter personal que nos facilite para la prestación de un servicio serán los mínimos exigibles para poder elaborar el contrato así como para poder ofrecerle los servicios que usted contrate y para el cumplimiento de las obligaciones legales. 
				Los datos serán conservados durante los plazos legalmente establecidos. 
				<br/><br/>
				<b>Datos recabados para la solicitud de información a través del formulario web</b><br/>
				Los datos personales recabados a través del formulario web para la petición de información tienen como finalidad el envío de información comercial sobre nuestros productos y servicios. 
				El tratamiento de los datos en este caso está legitimado por el consentimiento expreso que usted nos presta al aceptar el envío de la información aportada a través del formulario. 
				<br/><br/>
				<b>Ejercicio de los derechos </b><br/>
				De acuerdo con los derechos que confiere el Reglamento (UE) 2016/679 de protección de datos de carácter personal podrá ejercer los derechos de acceso, rectificación, limitación de tratamiento, supresión, portabilidad y oposición al tratamiento de sus datos dirigiendo su petición a la dirección postal indicada o al correo electrónico <i>vocality@vocality.ai</i>. 
				Para el ejercicio de los derechos deberá identificarse mediante la presentación de su DNI. 
				<br/><br/>
				<b>Destinatarios </b><br/>
				Vocality.ai informa a los usuarios de que sus datos personales no serán comunicados a terceros, con la salvedad de que dicha comunicación de datos este amparada en una obligación legal o cuando la prestación de un servicio implique la necesidad de una relación contractual con un encargado de tratamiento. En este último caso, los destinatarios de sus datos pueden ser empresas del grupo, empresas de solvencia patrimonial (en el caso de impagos, comunicados mediante los procedimientos y garantías establecidos por la normativa vigente) así como determinados encargados del tratamiento necesarios para la correcta prestación de los servicios). 
				Vocality.ai mantiene unos criterios estrictos de selección de encargados de tratamiento y se compromete contractualmente con cada uno a cumplir y hacer cumplir las obligaciones establecidas en materia de protección de datos. 
				<br/><br/>
				<b>Variación de los datos </b><br/>
				Mientras no nos comuniquen lo contrario, entenderemos que los datos no han sido modificados y que usted se compromete a notificarnos cualquier variación. 
				Para cualquier reclamación puede dirigirse Vocality.ai. Igualmente podrá dirigirse a la Agencia Española de Protección de Datos <i>www.aepd.es.</i>
				<br/>
				</p>
			</SoftTypography>
			</AccordionDetails>
		</Accordion>
		<Accordion>
			<AccordionSummary
			expandIcon={<ExpandMoreIcon />}
			aria-controls="panel2-content"
			id="panel2-header"
			>
			<SoftTypography>Política de Cookies</SoftTypography>
			</AccordionSummary>
			<AccordionDetails>
			<SoftTypography
				variant="body2"
				component='span'
			>
				<p>
				<b>Concepto de Cookies </b><br/>
				Las cookies constituyen una herramienta empleada por los servidores Web para almacenar y recuperar información acerca de sus visitantes o usuarios. No es más que un fichero de texto que algunos servidores instalan durante la navegación y que permiten conocer información como por ejemplo: el lugar desde el que accede, el tiempo de conexión, el dispositivo desde el que accede (fijo o móvil), el sistema operativo y navegador utilizados, las páginas más visitadas, el número de clicks realizados y de datos respecto al comportamiento del usuario en Internet. No proporcionan referencias que permitan deducir el nombre y apellidos del usuario y no pueden leer datos de su disco duro ni incluir virus en sus equipos. Asimismo, no puede leer las cookies implantadas en el disco duro del usuario desde otros servidores. <br/>
				<br/>
				<b>¿Por qué son importantes? </b><br/>
				El sitio web es accesible sin necesidad de que las cookies estén activadas, si bien, su desactivación puede impedir su funcionamiento correcto.
				Desde un punto de vista técnico las cookies permiten que los sitios web funcionen de forma más ágil y adaptada a las preferencias de los usuarios, como por ejemplo almacenar el idioma, la moneda del país o detectar el dispositivo de acceso.
				Establecen niveles de protección y seguridad que Impiden o dificultan ciberataques contra el sitio web o sus usuarios.
				Permiten que los gestores de los medios puedan conocer datos estadísticos recopilados para mejorar la calidad y experiencia de sus servicios.
				Sirven para optimizar la publicidad que mostramos a los usuarios, ofreciendo la que más se ajusta a sus intereses. <br/>
				<br/>
				<b>Autorización para el uso de cookies </b><br/>
				En el sitio web aparece un <b>Aviso de Cookies</b> que el usuario o visitante puede aceptar, consintiendo expresamente al uso de las cookies según se indica a continuación. 
				El usuario puede configurar su navegador para rechazar por defecto todas las cookies o para recibir un aviso en pantalla de la recepción de cada cookie y decidir sobre su instalación o no en su disco duro. <br/>
				<br/>
				<b>Configuración del navegador  </b><br/>
				Vocality recuerda a sus usuarios que el uso de cookies podrá estar sujeto a su aceptación durante la instalación o actualización del navegador utilizado por éstos. 
				Esta aceptación podrá ser revocada mediante las opciones de configuración de contenidos y privacidad disponibles en el mismo. El Titular recomienda a sus usuarios que consulten la ayuda de su navegador o acceda a las páginas web de ayuda de los principales navegadores: Firefox, Internet Explorer, Safari, Chrome. 
				Si desea más información acerca de cómo revocar el consentimiento prestado o sobre el procedimiento para deshabilitar las cookies, así como realizar alguna pregunta sobre la Política de Cookies, puede contactar con el titular a través de la siguiente dirección de correo electrónico <i>vocality@vocality.ai</i> indicando “Política de Cookies” en el asunto. <br/>
				<br/>
				<b>Tipos de Cookies que se utilizan en la Web  </b><br/>
				</p>
				El usuario que navega por la Web puede encontrar cookies insertadas directamente por el titular, o bien cookies insertadas por entidades distintas al titular, según lo detallado en los siguientes apartados: 
				<ul  style={{paddingLeft: 25}}>
					<li ><b>Cookies de sesión</b>, expiran cuando el usuario abandona la página o cierra el navegador, es decir, están activas mientras dura la visita al sitio web y por tanto son borradas de nuestro ordenador al abandonarlo. </li>
					<li ><b>Cookies permanentes</b>, expiran cuando se cumple el objetivo para el que sirven o bien cuando se borran manualmente, tienen fecha de borrado y se utilizan normalmente en proceso de compra online, personalizaciones o en el registro, para no tener que introducir una contraseña constantemente. </li>
				</ul> 			
				Según la entidad que gestiona el equipo o dominio desde donde se envían las cookies y trata los datos que se obtengan, podemos distinguir entre Cookies propias y de terceros. 
				<ul  style={{paddingLeft: 25}}>
					<li ><b>Cookies propias</b> son aquellas cookies que son enviadas al ordenador del usuario y son gestionadas exclusivamente por nosotros para el mejor funcionamiento del Sitio Web. La información que recabamos se emplea para mejorar la calidad de nuestro servicio y tu experiencia como usuario.  </li>
					<li ><b>Cookies de terceros</b>, cuando el usuario interactúa con el contenido de nuestro sitio Web también pueden establecerse cookies de terceros (por ejemplo, al pulsar botones de redes sociales o visionar vídeos alojados en otro sitio web), que son aquellas establecidas por un dominio diferente de nuestro Sitio Web. No podemos acceder a los datos almacenados en las cookies de otros sitios web cuando navegues en los citados sitios web.   </li>
				</ul> 
			</SoftTypography>
			</AccordionDetails>
		</Accordion>
		<Accordion>
			<AccordionSummary
			expandIcon={<ExpandMoreIcon />}
			aria-controls="panel2-content"
			id="panel2-header"
			>
			<SoftTypography>Política de Contenido</SoftTypography>
			</AccordionSummary>
			<AccordionDetails>
			<SoftTypography
				variant="body2"
				component='span'
			>
				<p>
				Todos estamos interesados en el uso legal de la voz clonada. Clientes, locutores, productores y autores.<br/>
				Eres el responsable de generar y usar de forma legal la voz clonada, y nosotros de que nuestra solución cumpla con todas las normas. Para ello Vocality aporta un tag a todos los audios para que en todo momento puedas saber si un audio es de tu propiedad y si se ha hecho un uso inadecuado del mismo. 
				</p>
			</SoftTypography>
			</AccordionDetails>
		</Accordion>
		<SoftTypography
			variant="caption"
			component='span'
		>
			<p><br/><i>Agencia Española de Protección de Datos | AEPD </i> </p>
		</SoftTypography>

	</SoftBox>
	);
}

export default LegalText;