/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";

function Footer({ light }) {

  const { size } = typography;

  return (
    <SoftBox component="footer" py={4}>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={8} sx={{ textAlign: "center" }}>
            <Link href="https://zalcu.com/" target="_blank">
              <SoftTypography variant="button" fontWeight="medium" color={light ? "white" : "dark"}>
              &copy; {new Date().getFullYear()},  &nbsp;Made with <Icon color="secondary" fontSize="inherit">
                favorite
              </Icon> by Zalcu.&nbsp;
              </SoftTypography>
            </Link>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  light: false,
};

// Typechecking props for the Footer
Footer.propTypes = {
  light: PropTypes.bool,
};

export default Footer;
