/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

// react-router components
import { Link } from "react-router-dom";

// sweetalert2 components
import Swal from "sweetalert2";

// @mui material components
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Tooltip from '@mui/material/Tooltip';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Divider from "@mui/material/Divider";
import { useTheme } from '@mui/material/styles';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftDropzone from "components/SoftDropzone";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftBadgeDot from "components/SoftBadgeDot";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import SpeakerUserForm from "layouts/management/components/SpeakerUserForm"

// Services
import { useGetVoicesQuery } from "services/voice";

function Users() {

  // Set last visited view
  localStorage.setItem("last_visited", "/manage/users");

  // Voices data
  const { data:speakers, refetch:refetchSpeakers } = useGetVoicesQuery();

  // Create user modal
  const [title, setTitle] = useState("");
  const [language, setLanguage] = useState("");
  const [open, setOpen] = useState(false);

  const handleClickOpen = (name, language) => {
    setTitle(name)
    setLanguage(language)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Table -----------------------------------------------------
  // Columns
  const columns= [
    { name: "nombre", align: "left" },
    { name: "título", align: "left" },
    { name: "idioma", align: "left" },
    { name: "usuario", align: "left" },
  ]

  // Create row
  function createTableRow(data) {
    return {
        "nombre": data.name,
        "título": data.title,
        "idioma": data.language,
        "usuario": (data.user_id ? data.user.name + " " + data.user.surname :
            <SoftBox ml={-1.325}>
                <SoftButton
                    variant="gradient"
                    color="secondary"
                    onClick={() => handleClickOpen(data.name, data.language)}
                >
                    {"crear usuario"}
                </SoftButton>
            </SoftBox>
          ),
    }
  }

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox py={3}>
          <Card sx={{ height: "100%" }}>
              <SoftBox padding="1rem">
                <SoftBox mb={2}>
                  <SoftTypography variant="h4" fontWeight="medium">
                      Gestión de usuarios
                  </SoftTypography>
                  <Table
                      columns= {columns}
                      rows= {speakers ? speakers?.map(createTableRow) : []}
                  />
                </SoftBox>
              </SoftBox>
          </Card>
        </SoftBox>
        <Footer />
      </DashboardLayout>
      <SpeakerUserForm
        title={title}
        language={language}
        handleClose={handleClose}
        open={open}
      />
    </div>
    
  );
}

export default Users;
