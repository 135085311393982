// Auth
import { createSlice } from "@reduxjs/toolkit";
import { authenticate, refreshToken } from "./thunks";

export const statusEnum = {
    authenticated: 'Authenticated',
    nonAuthenticated: 'Non-Authenticated',
    checkingAuthentication: 'Checking',
};

const userToken = localStorage.getItem('token');

const initialState = {
    status: statusEnum.nonAuthenticated,
    error: null,
    userToken: userToken,
    userProfile: null,
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout: () => {
            localStorage.removeItem('token');
            return initialState;
        },
    },
    extraReducers: (builder) => {

        // Login user
        builder.addCase(authenticate.fulfilled, (state, action) => {
            state.status = statusEnum.authenticated;
            state.error = null;
            state.userToken = action.payload.userToken;
            state.userProfile = action.payload.userProfile;
        });
        builder.addCase(authenticate.rejected, (state, action) => {
            state.status = statusEnum.nonAuthenticated;
            state.error = action.error;
            state.userToken = null;
            state.userProfile = null;
        });
        builder.addCase(authenticate.pending, (state, action) => {
            state.status = statusEnum.checkingAuthentication;
            state.error = null;
        });

        // Refresh token
        builder.addCase(refreshToken.fulfilled, (state, action) => {
            state.status = statusEnum.authenticated;
            state.error = null;
            state.userProfile = action.payload.userProfile;
            state.userToken = action.payload.userToken;
        });

        builder.addCase(refreshToken.rejected, (state, action) => {
            state.status = statusEnum.nonAuthenticated;
            state.error = null;
            state.userProfile = null;
            state.userToken = null;
        });

        builder.addCase(refreshToken.pending, (state, action) => {
            state.status = statusEnum.checkingAuthentication;
            state.error = null;
        });
    }
});

export default authSlice.reducer;
export const { logout } = authSlice.actions;