/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  11. The `component` key is used to store the component of its route.
  12. The `default` key indicated whether a route is the default one when logging in the App or not
  13. The `roles` key is an array which contains all the roles that can access the route
  14. The `loggedIn` key indicates if a user needs to be logged in to access the route
*/

/*
NOTE FROM ZALCU DEVELOPER:

Many layouts/examples/etc from the Soft IU template haven't been deleted from the code because, even though they aren't used in the VocalityAI App, they could be useful for future developments
*/

// Vocality Layouts
import Default from "layouts/dashboards/default";
import History from "layouts/dashboards/history";
import SpeakerHistory from "layouts/dashboards/speakerhistory";
import Users from "layouts/management/users";
import SignIn from "layouts/authentication/sign-in";
import Register from "layouts/authentication/register";
import RegisterSpeaker from "layouts/authentication/register-speaker";
import ResetPassword from "layouts/authentication/reset-password";
import VerifyUser from "layouts/authentication/verify-user";
import Error404 from "layouts/authentication/error/404";
import Error500 from "layouts/authentication/error/500";

// Soft UI Dashboard PRO React layouts
import Automotive from "template/layouts/dashboards/automotive";
import SmartHome from "template/layouts/dashboards/smart-home";
import VRDefault from "template/layouts/dashboards/virtual-reality/vr-default";
import VRInfo from "template/layouts/dashboards/virtual-reality/vr-info";
import CRM from "template/layouts/dashboards/crm";
import ProfileOverview from "template/layouts/pages/profile/profile-overview";
import Teams from "template/layouts/pages/profile/teams";
import AllProjects from "template/layouts/pages/profile/all-projects";
import Reports from "template/layouts/pages/users/reports";
// import NewUser from "template/layouts/pages/users/new-user";
import Settings from "template/layouts/pages/account/settings";
import Billing from "template/layouts/pages/account/billing";
import Invoice from "template/layouts/pages/account/invoice";
import Security from "template/layouts/pages/account/security";
import General from "template/layouts/pages/projects/general";
import Timeline from "template/layouts/pages/projects/timeline";
import NewProject from "template/layouts/pages/projects/new-project";
import Widgets from "template/layouts/pages/widgets";
import Charts from "template/layouts/pages/charts";
import SweetAlerts from "template/layouts/pages/sweet-alerts";
import Notifications from "template/layouts/pages/notifications";
import PricingPage from "template/layouts/pages/pricing-page";
import RTL from "template/layouts/pages/rtl";
import Kanban from "template/layouts/applications/kanban";
import Wizard from "template/layouts/applications/wizard";
import DataTables from "template/layouts/applications/data-tables";
import Calendar from "template/layouts/applications/calendar";
import Analytics from "template/layouts/applications/analytics";
import Overview from "template/layouts/ecommerce/overview";
import NewProduct from "template/layouts/ecommerce/products/new-product";
import EditProduct from "template/layouts/ecommerce/products/edit-product";
import ProductPage from "template/layouts/ecommerce/products/product-page";
import ProductsList from "template/layouts/ecommerce/products/products-list";
import OrderList from "template/layouts/ecommerce/orders/order-list";
import OrderDetails from "template/layouts/ecommerce/orders/order-details";
import Referral from "template/layouts/ecommerce/referral";

// Soft UI Dashboard PRO React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import SettingsIcon from "examples/Icons/Settings";
import Basket from "examples/Icons/Basket";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";

// Roles
import { ROLES } from 'constants/roles';

const routes = [
  {
    type: "collapse",
    name: "Generar voz",
    key: "generate",
    route: "/generate",
    icon: <CustomerSupport size="12px" />,
    component: <Default />,
    default: true,
    roles: [ROLES.ADMIN, ROLES.PRODUCTOR, ROLES.TECHNICIAN],
    loggedIn: true,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Histórico",
    key: "history",
    route: "/history",
    notifications: 0,
    icon: <Document size="12px" />,
    component: <SpeakerHistory mode={0}/>,
    default: true,
    roles: [ROLES.SPEAKER],
    loggedIn: true,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Histórico",
    key: "history",
    route: "/history",
    notifications: 0,
    icon: <Document size="12px" />,
    component: <History mode={0}/>,
    roles: [ROLES.ADMIN, ROLES.PRODUCTOR, ROLES.TECHNICIAN],
    loggedIn: true,
    noCollapse: true,
  },
  { type: "divider", key: "divider-1", roles: [ROLES.SUPERUSER] },
  { type: "title", title: "Administración", key: "admin", roles: [ROLES.SUPERUSER] },
  {
    type: "collapse",
    name: "Facturación",
    key: "billing",
    icon: <CreditCard size="12px" />,
    roles: [ROLES.SUPERUSER],
    loggedIn: true,
    collapse: [
      {
        name: "Clientes",
        key: "clientes",
        route: "/billing/clients",
        component: <History mode={1}/>,
      },
      {
        name: "Locutores",
        key: "locutores",
        route: "/billing/speakers",
        component: <SpeakerHistory mode={1}/>,
      }
    ],
  },
  {
    type: "collapse",
    name: "Gestión",
    key: "manage",
    icon: <SettingsIcon size="12px" />,
    roles: [ROLES.SUPERUSER],
    loggedIn: true,
    collapse: [
      {
        name: "Usuarios",
        key: "usuarios",
        route: "/manage/users",
        component: <Users/>,
      }
    ],
  },
  {
    name: "Authentication",
    key: "authentication",
    icon: <Document size="12px" />,
    loggedIn: false,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        route: "/authentication/sign-in",
        component: <SignIn />,
        noCollapse: true,
      },
      {
        name: "Register",
        key: "register",
        route: "/authentication/register",
        component: <Register />,
        noCollapse: true,
      },
      {
        name: "Register Speaker",
        key: "register_speaker",
        route: "/authentication/register_speaker",
        component: <RegisterSpeaker />,
        noCollapse: true,
      },
      {
        name: "Reset Password",
        key: "reset-password",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/reset-password",
            component: <ResetPassword />,
          },
        ],
      },
      {
        name: "Verify User",
        key: "verify",
        collapse: [
          {
            name: "Verify",
            key: "verify",
            route: "/authentication/verify-user",
            component: <VerifyUser />,
          },
        ],
      },
      {
        name: "Error",
        key: "error",
        collapse: [
          {
            name: "Error 404",
            key: "error-404",
            route: "/authentication/error/404",
            component: <Error404 />,
          },
          {
            name: "Error 500",
            key: "error-500",
            route: "/authentication/error/500",
            component: <Error500 />,
          },
        ],
      },
    ],
  },
  /*
  FOR DEVELOPERS:
  The following routes are Soft UI templates and examples; uncomment this in order to see them. They can help create new visual develoments with ease.
  */
  // { type: "divider", key: "divider-2" },
  // { type: "title", title: "Examples", key: "examples" },
  // {
  //   type: "collapse",
  //   name: "Dashboards",
  //   key: "templates",
  //   icon: <SpaceShip size="12px" />,
  //   collapse: [
  //     {
  //       name: "Automotive",
  //       key: "automotive",
  //       route: "/templates/automotive",
  //       component: <Automotive />,
  //     },
  //     {
  //       name: "Smart Home",
  //       key: "smart-home",
  //       route: "/templates/smart-home",
  //       component: <SmartHome />,
  //     },
  //     {
  //       name: "Virtual Reality",
  //       key: "virtual-reality",
  //       collapse: [
  //         {
  //           name: "VR Default",
  //           key: "vr-default",
  //           route: "/templates/virtual-reality/default",
  //           component: <VRDefault />,
  //         },
  //         {
  //           name: "VR Info",
  //           key: "vr-info",
  //           route: "/templates/virtual-reality/info",
  //           component: <VRInfo />,
  //         },
  //       ],
  //     },
  //     { name: "CRM", key: "crm", route: "/templates/crm", component: <CRM /> },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Pages",
  //   key: "pages",
  //   icon: <Document size="12px" />,
  //   collapse: [
  //     {
  //       name: "Profile",
  //       key: "profile",
  //       collapse: [
  //         {
  //           name: "Profile Overview",
  //           key: "profile-overview",
  //           route: "/pages/profile/profile-overview",
  //           component: <ProfileOverview />,
  //         },
  //         {
  //           name: "Teams",
  //           key: "teams",
  //           route: "/pages/profile/teams",
  //           component: <Teams />,
  //         },
  //         {
  //           name: "All Projects",
  //           key: "all-projects",
  //           route: "/pages/profile/all-projects",
  //           component: <AllProjects />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Users",
  //       key: "users",
  //       collapse: [
  //         {
  //           name: "Reports",
  //           key: "reports",
  //           route: "/pages/users/reports",
  //           component: <Reports />,
  //         },
  //         {
  //           name: "New User",
  //           key: "new-user",
  //           route: "/pages/users/new-user",
  //           component: <NewUser />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Account",
  //       key: "account",
  //       collapse: [
  //         {
  //           name: "Settings",
  //           key: "settings",
  //           route: "/pages/account/settings",
  //           component: <Settings />,
  //         },
  //         {
  //           name: "Billing",
  //           key: "billing",
  //           route: "/pages/account/billing",
  //           component: <Billing />,
  //         },
  //         {
  //           name: "Invoice",
  //           key: "invoice",
  //           route: "/pages/account/invoice",
  //           component: <Invoice />,
  //         },
  //         {
  //           name: "Security",
  //           key: "security",
  //           route: "/pages/account/security",
  //           component: <Security />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Projects",
  //       key: "projects",
  //       collapse: [
  //         {
  //           name: "General",
  //           key: "general",
  //           route: "/pages/projects/general",
  //           component: <General />,
  //         },
  //         {
  //           name: "Timeline",
  //           key: "timeline",
  //           route: "/pages/projects/timeline",
  //           component: <Timeline />,
  //         },
  //         {
  //           name: "New Project",
  //           key: "new-project",
  //           route: "/pages/projects/new-project",
  //           component: <NewProject />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Pricing Page",
  //       key: "pricing-page",
  //       route: "/pages/pricing-page",
  //       component: <PricingPage />,
  //     },
  //     { name: "RTL", key: "rtl", route: "/pages/rtl", component: <RTL /> },
  //     { name: "Widgets", key: "widgets", route: "/pages/widgets", component: <Widgets /> },
  //     { name: "Charts", key: "charts", route: "/pages/charts", component: <Charts /> },
  //     {
  //       name: "Sweet Alerts",
  //       key: "sweet-alerts",
  //       route: "/pages/sweet-alerts",
  //       component: <SweetAlerts />,
  //     },
  //     {
  //       name: "Notfications",
  //       key: "notifications",
  //       route: "/pages/notifications",
  //       component: <Notifications />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Applications",
  //   key: "applications",
  //   icon: <SettingsIcon size="12px" />,
  //   collapse: [
  //     {
  //       name: "Kanban",
  //       key: "kanban",
  //       route: "/applications/kanban",
  //       component: <Kanban />,
  //     },
  //     {
  //       name: "Wizard",
  //       key: "wizard",
  //       route: "/applications/wizard",
  //       component: <Wizard />,
  //     },
  //     {
  //       name: "Data Tables",
  //       key: "data-tables",
  //       route: "/applications/data-tables",
  //       component: <DataTables />,
  //     },
  //     {
  //       name: "Calendar",
  //       key: "calendar",
  //       route: "/applications/calendar",
  //       component: <Calendar />,
  //     },
  //     {
  //       name: "Analytics",
  //       key: "analytics",
  //       route: "/applications/analytics",
  //       component: <Analytics />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Ecommerce",
  //   key: "ecommerce",
  //   icon: <Basket size="12px" />,
  //   collapse: [
  //     {
  //       name: "Overview",
  //       key: "overview",
  //       route: "/ecommerce/overview",
  //       component: <Overview />,
  //     },
  //     {
  //       name: "Products",
  //       key: "products",
  //       collapse: [
  //         {
  //           name: "New Product",
  //           key: "new-product",
  //           route: "/ecommerce/products/new-product",
  //           component: <NewProduct />,
  //         },
  //         {
  //           name: "Edit Product",
  //           key: "edit-product",
  //           route: "/ecommerce/products/edit-product",
  //           component: <EditProduct />,
  //         },
  //         {
  //           name: "Product Page",
  //           key: "product-page",
  //           route: "/ecommerce/products/product-page",
  //           component: <ProductPage />,
  //         },
  //         {
  //           name: "Products List",
  //           key: "products-list",
  //           route: "/ecommerce/products/products-list",
  //           component: <ProductsList />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Orders",
  //       key: "orders",
  //       collapse: [
  //         {
  //           name: "Order List",
  //           key: "order-list",
  //           route: "/ecommerce/orders/order-list",
  //           component: <OrderList />,
  //         },
  //         {
  //           name: "Order Details",
  //           key: "order-details",
  //           route: "/ecommerce/orders/order-details",
  //           component: <OrderDetails />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Referral",
  //       key: "referral",
  //       route: "/ecommerce/referral",
  //       component: <Referral />,
  //     },
  //   ],
  // },
];

export default routes;
