
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { api } from '../app/baseQuery'

const productApi = api.injectEndpoints({
    tagTypes: ['product'],
    endpoints: (build) => ({

        /**
         * Get products - Devuelve los productos de stripe
         */
        getProducts: build.query({
            query: () => 'db/products/read',
        }),

    }),
    overrideExisting: false,
})

export const { 
    useGetProductsQuery,
} = productApi