import { useCallback, useEffect, useRef, useState } from "react";

import { createSetupIntent, cancelSetupIntent } from "api/stripe";

function useStripeSetupIntent({ customerID }) {

    const [errorMessage, setErrorMessage] = useState(null);
    const [setupIntent, setSetupIntent] = useState(null);

    const createInitialSetupIntent = useCallback(async () => {
        try {
            const newSetupIntent = await createSetupIntent( customerID );
            setSetupIntent(newSetupIntent);
        } catch (error) {
            setErrorMessage(error.message);
        }
    }, []);

    useEffect(() => {

        createInitialSetupIntent();
        

        return () => {
            if (setupIntent && setupIntent.status === 'requires_payment_method') {
                try {
                    // cancelSetupIntent(setupIntent.id);
                    // console.log("Canceling setup intent", { id: setupIntent.id });
                } catch (error) {
                    setErrorMessage(error.message);
                }
            }
        };
    }, []);

    return {
        setupIntent,
        errorMessage,
    };
}

export { useStripeSetupIntent };
