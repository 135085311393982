// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Lottie animations
import Lottie from 'react-lottie';
import waitData from 'assets/images/animations/vocality_wait';

function Loading() {
    
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: waitData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div>
        <Lottie 
        options={defaultOptions}
        height={80}
        width={80}
        />
    </div>
  );
}

export default Loading;
