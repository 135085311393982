/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
import { ErrorMessage, Field } from "formik";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

function FormSelect({ label, name, options, placeholder, ...rest }) {
    return (
        <SoftBox mb={1.5}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                    component="label"
                    variant="h6"
                    fontWeight="bold"
                    textTransform="capitalize"
                >
                    {label}
                </SoftTypography>
            </SoftBox>
            <Field 
                {...rest} 
                name={name} 
                as={
                    () => <SoftSelect options={options} placeholder={placeholder} defaultValue={options[0]} 
                />}
            >
                
            </Field>

            <SoftBox mt={0.75}>
                <SoftTypography component="div" variant="caption" color="error">
                    <ErrorMessage name={name} />
                </SoftTypography>
            </SoftBox>
        </SoftBox>
    );
}

// typechecking props for FormField
FormSelect.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string.isRequired,
};

export default FormSelect;
