/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// page components
import FormField from "layouts/authentication/components/FormField";
import FormSelect from "layouts/authentication/components/FormSelect";

function SpeakerProfileInputs({ formFields, values, errors, touched }) {
  const { 
    email: emailF, 
} = formFields;
  const {
    email: emailV,
  } = values;

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          Se enviará un mensaje a la dirección que introduzcas para que el usuario pueda completar su registro en la App.
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={emailF.type}
              label={emailF.label}
              name={emailF.name}
              value={emailV}
              placeholder={emailF.placeholder}
              error={errors.email && touched.email}
              success={emailV.length > 0 && !errors.email}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props
SpeakerProfileInputs.propTypes = {
    formFields: PropTypes.object,
    values: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.object,
};

export default SpeakerProfileInputs;
