/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "layouts/authentication/reset-password/schemas/form";

const {
  formField: { password, repeatPassword },
} = checkout;

const validations =
  Yup.object().shape({
    [password.name]: Yup.string().required(password.errorMsg).min(8, password.invalidMsg).matches(/^(?=.*[0-9])/, password.invalidMsg).matches(/^(?=.*[A-Z])/, password.invalidMsg),
    [password.name]: Yup.string().required(password.errorMsg).min(8, password.invalidMsg).matches(/^(?=.*[0-9])/, password.invalidMsg).matches(/^(?=.*[A-Z])/, password.invalidMsg),
    [repeatPassword.name]: Yup.string()
      .required(repeatPassword.errorMsg)
      .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
  });

export default validations;
