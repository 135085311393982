/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useSelector } from "react-redux";
import { useMemo } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Custom hooks
import { useRegisterAdmin } from "hooks/useRegisterAdmin";

// Custom components
import StepperCardLayout from "layouts/authentication/components/StepperCardLayout";
import { RegisterStepper } from "../components/register/Stepper";
import { UserProfileForm } from "../components/register/UserProfileForm";
import { BillingForm } from "../components/register/BillingForm";


/**
 * Register form
 */
const RegisterForm = () => {

    //User
    const user = useSelector((state) => state.auth.userProfile);

    const {
        error,
        requestState,
        registeredUser,
        handleUserProfileSubmit,
    } = useRegisterAdmin();

    const activeStep = useMemo(() => {
        return registeredUser ? 1 : 0;
    }, [registeredUser]);

    const customerID = useMemo(() => {
        return registeredUser?.customer_id;
    }, [registeredUser]);

    if(user){
        return (
            <Grid item sx={{ width: "65%" }}>
                {/* Datos de pago */}
                <BillingForm customerID={user.customer_id} returnURL={process.env.REACT_APP_URL_SCHEME}/>
            </Grid>
        );
    } else {
        return (
            <Grid item sx={{ width: "65%" }}>
    
                {/* Stepper */}
                <RegisterStepper activeStep={activeStep} />
    
                {/* Step 1 - Datos de usuario */}
                { activeStep === 0 && <UserProfileForm handleSubmit={handleUserProfileSubmit} />}
    
                {/* Step 2 - Datos de pago */}
                { activeStep === 1 && customerID && <BillingForm customerID={customerID} returnURL={process.env.REACT_APP_URL_SCHEME}/>}         
    
            </Grid>
        );
    }

};

const RegisterPage = () => {

    return (
        <StepperCardLayout>
            <SoftBox py={3}>
                <Grid container justifyContent="center" sx={{ height: "100%" }}>
                  <RegisterForm />
                </Grid>
            </SoftBox>
        </StepperCardLayout>
    );
};

// typechecking props for Register
RegisterPage.propTypes = {
    stripe: PropTypes.object,
};

export default RegisterPage;
