
import * as Yup from "yup";

/**
 * Schema for the user profile creation form.
 */
const speakerProfileCreationSchema = {
    formID: "new-user-profile-creation-form",
    formFields: {
        email: {
            name: "email",
            label: "Correo electrónico",
            type: "email",
            placeholder: "Escribe aquí el correo electrónico",
            errorMsg: "Es necesario completar correo electrónico.",
            invalidMsg: "La dirección no es válida.",
        },
    },
};

// Destructure the form schema
const { formID } = speakerProfileCreationSchema;
const { formFields } = speakerProfileCreationSchema;
const { email } = formFields;

const initialSpeakerProfileValues = {
    [email.name]: "",
};

const speakerProfileValidations = Yup.object().shape({
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
  })

export { 
    formID,
    formFields,
    initialSpeakerProfileValues,
    speakerProfileValidations
};
