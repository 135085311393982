
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { api } from '../app/baseQuery'

const speakerApi = api.injectEndpoints({
    tagTypes: ['speaker'],
    endpoints: (build) => ({

        /**
         * Decode speaker create token
         */
        getDecodedInvite: build.query({
            query: (token) => `auth/register/speaker/decode-invitation-token?token=${token}`,
        }),

    }),
    overrideExisting: false,
})

export const { 
    useGetDecodedInviteQuery,
} = speakerApi