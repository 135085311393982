
import * as Yup from "yup";

/**
 * Schema for the user profile creation form.
 */
const speakerProfileCreationSchema = {
    formID: "new-user-profile-creation-form",
    formFields: {
        firstName: {
            name: "firstName",
            label: "Nombre",
            type: "text",
            placeholder: "Escribe aquí tu nombre",
            errorMsg: "Es necesario completar nombre.",
        },
        lastName: {
            name: "lastName",
            label: "Apellidos",
            type: "text",
            placeholder: "Escribe aquí tus apellidos",
            errorMsg: "Es necesario completar apellidos.",
        },
        password: {
            name: "password",
            label: "Contraseña",
            type: "password",
            placeholder: "******",
            errorMsg: "Es necesario indicar contraseña.",
            invalidMsg:
                "La contraseña necesita tener al menos 8 caracteres, una mayúcula y un número.",
        },
        repeatPassword: {
            name: "repeatPassword",
            label: "Repetir contraseña",
            type: "password",
            placeholder: "******",
            errorMsg: "Es necesario indicar contraseña.",
            invalidMsg: "Las contraseñas no son iguales.",
        },
        termsOfService: {
            name: "termsOfService",
            label: "Acepto los términos y condiciones",
            type: "checkbox",
            errorMsg: "Es necesario aceptar los términos y condiciones.",
        },
    },
};

// Destructure the form schema
const { formID } = speakerProfileCreationSchema;
const { formFields } = speakerProfileCreationSchema;
const { firstName, lastName, email, password, repeatPassword, company, taxIDType, taxID, termsOfService  } = formFields;

const initialSpeakerProfileValues = {
    [firstName.name]: "",
    [lastName.name]: "",
    [password.name]: "",
    [repeatPassword.name]: "",
    [termsOfService.name]: false,
};

const speakerProfileValidations = Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg),
    [lastName.name]: Yup.string().required(lastName.errorMsg),
    [password.name]: Yup.string().required(password.errorMsg).min(8, password.invalidMsg).matches(/^(?=.*[0-9])/, password.invalidMsg).matches(/^(?=.*[A-Z])/, password.invalidMsg),
    [password.name]: Yup.string().required(password.errorMsg).min(8, password.invalidMsg).matches(/^(?=.*[0-9])/, password.invalidMsg).matches(/^(?=.*[A-Z])/, password.invalidMsg),
    [repeatPassword.name]: Yup.string()
      .required(repeatPassword.errorMsg)
      .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
    [termsOfService.name]: Yup.boolean().oneOf([true], termsOfService.errorMsg),

  })

export { 
    formID,
    formFields,
    initialSpeakerProfileValues,
    speakerProfileValidations
};
