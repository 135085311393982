/**
 * @fileoverview This file contains the functions that handle the authentication of the user.
 */

import { createAsyncThunk } from '@reduxjs/toolkit'

import { getAccessToken, getUserInfo, getNewToken } from 'api/auth';

/**
 * Thunk to login the user.
 */
const authenticate = createAsyncThunk(
    'auth/jwt/login',
    async (credentials, thunkAPI) => {
        const userToken = await getAccessToken(credentials);
        const userInfo = await getUserInfo();
        return {
            userToken: userToken.access_token,
            userProfile: userInfo
        }
    }
);

/**
 * Thunk to refresh the user token.
 */
const refreshToken = createAsyncThunk(
    'auth/jwt/refresh',
    async (thunkAPI) => {
        const userToken = await getNewToken();
        const userProfile = await getUserInfo();
        
        localStorage.setItem('token', userToken.access_token);

        return {userProfile, userToken: userToken.access_token};
    }
);

export {
    authenticate,
    refreshToken
}