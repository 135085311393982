import PropTypes from "prop-types";
import { useState, useMemo } from "react";

// Formik - https://formik.org/
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import Loading from "examples/Animations/Loading";

// Formik layout schemas for form and form fields
import { 
    formID,
    formFields,
    initialSpeakerProfileValues,
    speakerProfileValidations
} from "./schema";

// Custom components
import SpeakerProfileInputs from "./inputs";
import { TermsOfService } from "./TermsOfService";

function SpeakerProfileForm({ handleSubmit }) {
    
    return (
        <Formik
            initialValues={initialSpeakerProfileValues}
            validationSchema={speakerProfileValidations}
            onSubmit={handleSubmit}
        >
            {({ values, errors, touched, isSubmitting }) => (
                <Form id={formID} autoComplete="off">
                    <Card sx={{ height: "100%" }}>
                        <SoftBox p={2}>
                            <SoftBox>

                                <SpeakerProfileInputs 
                                    formFields={formFields}   
                                    values={values}
                                    errors={errors}
                                    touched={touched} 
                                />
                                <Divider />
                                <TermsOfService />

                                <SoftBox
                                    mt={2}
                                    width="100%"
                                    display="flex"
                                    justifyContent="space-between"
                                >

                                    {/* Loading */}
                                    {isSubmitting && <Loading />}

                                    {/* Next step button */}
                                    {!isSubmitting && <SoftButton
                                        type="submit"
                                        variant="gradient"
                                        color="secondary"
                                        disabled={isSubmitting}
                                    >
                                        Registrar
                                    </SoftButton>}
                                    
                                </SoftBox>

                            </SoftBox>
                        </SoftBox>
                    </Card>
                </Form>
            )}
        </Formik>
    );
}

SpeakerProfileForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

export { SpeakerProfileForm };
