import PropTypes from 'prop-types';

// Material UI Stepper component for the registration process
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Steps for the registration process
import { registerSteps } from './steps';

/**
 * Stepper component for the registration process
 * @param {Object} props
 * @param {number} props.activeStep - The current step of the stepper
 * @returns {JSX.Element} - Stepper component
 */
function RegisterStepper({ activeStep }) {

    if (
        Array.from({length: registerSteps.length}, (_, index) => index ).indexOf(activeStep) === -1) {
        throw new Error(`Invalid active step. Active step must be from 0 to ${registerSteps.length - 1}.`);
    }

    return (
        <Stepper activeStep={activeStep} alternativeLabel>
            {registerSteps.map(
                ({label}) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                )
            )}
        </Stepper>
    )
}

RegisterStepper.propTypes = {
    activeStep: PropTypes.number.isRequired,
}

export {
    RegisterStepper,
}