/**
 * Resolves the audio sample path based on the provided voice Name and voices list
 * TODO: Implement a better logic to resolve the audio sample path
 * @param {string} voiceId The voice ID to resolve the audio sample path for
 * @returns {string} The resolved audio sample path
 */
function getAudioSamplePath(voicesList, voiceName) {

  const voiceId = voicesList.find(voice => voice.name === voiceName).id;

  return `/files/voice_samples/${voiceId}.mp3`;
}

export { 
    getAudioSamplePath 
};