/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useSelector } from "react-redux";

import { useEffect, useState } from "react";

// react-router components
import {  Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Tooltip from '@mui/material/Tooltip';
import IconButton from "@mui/material/IconButton";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DefaultLineChart from "examples/Charts/DefaultLineChart";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ExportDataTable from "layouts/dashboards/components/ExportDataTable";

// Request
import { REQUESTSTATE } from "constants/request";

// Services
import { useGetProductsQuery } from "services/product";
import { useGetInferenceByUserQuery, useGetInferencesQuery } from 'services/inference'
import { useGetVoicesQuery } from "services/voice";

//Excel export
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import JSZip from "jszip";
function SpeakerHistory({ mode }) {

  //Set last visited view
  localStorage.setItem("last_visited", mode ? "/billing/speakers" : "/history");

  //Inference data 
  const { data:inferences, refetch: refetchInferences } = mode ? useGetInferencesQuery() : useGetInferenceByUserQuery();

  // Products
  const { data:products } = useGetProductsQuery(); // Fetch products from stripe

  // Voices data
  const { data:servspeakers } = useGetVoicesQuery();

  //User
  const user = useSelector((state) => state.auth.userProfile);

  //General usestates
  const [graphYear, setGraphYear] = useState(new Date().getFullYear());

  //General handlers
  const handleGraphYearChange = (inputValue, { action, prevInputValue }) => {
    setGraphYear(inputValue.value);
  }

  //Superuser view only--------------------------------------
  //Usestates
  const speakerDefault = "Todos los locutores"
  //---------------------------------------------------------

  useEffect(() => {
    refetchInferences()
  }, [user])

  // Table -----------------------------------------------------
  // Columns
  const columns= [
    { Header: "título", accessor: "titulo" },
    { Header: "tipo de producto", accessor: "producto" },
    { Header: "idioma", accessor: "idioma" },
    { Header: "locutor", accessor: "locutor", filter: 'equals' },
    { Header: "fecha de creación", accessor: "fecha_cre" },
    { Header: "fecha_anyo", accessor: "fecha_anyo" },
    { Header: "fecha_mes", accessor: "fecha_mes" },
    { Header: "duración", accessor: "duracion" },
    { Header: "tarifa", accessor: "tarifa" },
    { Header: "total", accessor: "total" },
  ]

  // Create row
  function createTableRow(data) {

    const convertSeconds = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.round(((seconds % 60) + Number.EPSILON) * 100) / 100;
      return `${minutes}min ${remainingSeconds}s`;
    };

    if (inferences){
      return {
        id: data.id,
        titulo: data.title,
        locutor: servspeakers ? (servspeakers.find((element) => element.name == data.voz) ? servspeakers.find((element) => element.name == data.voz).title : "---") : "---",
        producto: products ? products.filter((prod) => prod.id == data.product_id)[0].name : "---",
        idioma: data.idioma,
        fecha_cre: new Date(data.created_at).toLocaleDateString('es'),
        fecha_anyo: new Date(data.created_at).getFullYear(),
        fecha_mes: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dec"][new Date(data.created_at).getMonth()],
        fecha_cre_entera: new Date(data.created_at),
        duracion: convertSeconds(data.audio_length),
        tarifa: (data.invoice ? (Math.round(((data.invoice.price.cost) + Number.EPSILON) * 100) / 100) : 0) + "€/minuto",
        total: (data.invoice ? (Math.round(((data.invoice.cost) + Number.EPSILON) * 100) / 100) : 0) + "€",
      }
    } else {
      return []
    }
  }

  // Charts--------------------------------------------------------------
  function createGraphYearOptions() {
    if (inferences){
      var existingYears = []
      var yearOptions = inferences.map(function(element){
        var year = new Date(element.created_at).getFullYear()
        if (existingYears.includes(year)){
          return null;
        } else {
          existingYears.push(year)
          return { value: year, label: year };
        }
      })
      // We remove null values
      return yearOptions.filter(n => n)
    } else {
      return []
    }
  }

  // MINUTES
  function createMinutesArray(year, month) {
    if (inferences){
      var yearData = inferences.filter(obj => {
        return new Date(obj.created_at).getFullYear() === year
      });
  
      return [...Array(12).keys()].map(function(element){
  
        var monthData = yearData.filter(obj => {
          return new Date(obj.created_at).getMonth() === element
        });
  
        return element > month ? null : monthData.reduce((n, {audio_length}) => n + audio_length, 0) / 60;
      })
    }
    else{
      return []
    }
  }

  const minutesHistoryData = {
    labels: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Minutos de locución",
        color: "info",
        data: createMinutesArray(graphYear, graphYear == new Date().getFullYear() ? new Date().getMonth() : 11),
      },
    ],
  };

  // PRICE
  function createPriceArray(year, month) {
    if (inferences){
      var priceData = inferences.filter(obj => {
        return new Date(obj.created_at).getFullYear() === year
      });
  
      return [...Array(12).keys()].map(function(element){
  
        var monthData = priceData.filter(obj => {
          return new Date(obj.created_at).getMonth() === element
        });
        //CALCULATE PRICE HERE
        return element > month ? null : monthData.map(function(el){
          return el.invoice ? Math.round((el.invoice.cost + Number.EPSILON) * 100) / 100 : null;
        }).reduce((a, b) => a + b, 0)
      })
    }
    else{
      return []
    }
  }

  const priceHistoryData = {
    labels: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "€",
        color: "dark",
        data: createPriceArray(graphYear, graphYear == new Date().getFullYear() ? new Date().getMonth() : 11),
      },
    ],
  };

  // ----------------------------------------------------------------------

  //Superuser view only: Excel export-------------------------------------

  function createSpeakerOptions() {
    if (inferences){
        var existingSpeakers = []
        var speakerOptions = inferences.map(function(element){
          var speaker = element.voz
          if (existingSpeakers.includes(speaker)){
            return null;
          } else {
            existingSpeakers.push(speaker)
            return { value: speaker, label: speaker };
          }
        })
        // We remove null values
        return [{value: "Todos los locutores", label: "Todos los locutores"}].concat(speakerOptions.filter(n => n))
      } else {
        return []
      }
  }

    // Export Excel
    function exportExcel(ids, speaker) {
      var filteredData = []
      inferences.map(function(element){
        if (ids.includes(element.id)){
          filteredData.push(element)
        }
      })

      if (speaker == "Todos los locutores"){
        var zip = new JSZip();
        var speakerArray = createSpeakerOptions().slice(1)
        
        for(var i=0;i<speakerArray.length;i++)
        {
          var auxData = filteredData.filter(obj => {
            return obj.voz === speakerArray[i].value
          });
          var filename = "_"
          var ws = XLSX.utils.json_to_sheet(auxData.map(function(element){
            filename = element.voz + Math.random().toString(16).slice(2)
            return {
              Usuario: element.user.name + " " + element.user.surname,
              Título: element.title,
              Producto: products ? products.filter((prod) => prod.id == element.product_id)[0].name : "---",
              Locutor: element.voz,
              Idioma: element.idioma,
              Fecha_creación: new Date(element.created_at).toLocaleDateString('es'),
              Duración: (element.audio_length ? (Math.round((element.audio_length + Number.EPSILON) * 100) / 100) : 0) + "s",
              Tarifa: (element.invoice ? (Math.round(((element.invoice.price.cost) + Number.EPSILON) * 100) / 100) : 0) + "€/minuto",
              Total: (element.invoice ? (Math.round(((element.invoice.cost) + Number.EPSILON) * 100) / 100) : 0) + "€",
            }
          }));
          var wb = {Sheets: {'data': ws}, SheetNames: ['data']};
          var excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
          
          if (filename != "_"){
            zip.file(filename + '.xlsx', excelBuffer, {binary: true});
          }
        }
            
        zip.generateAsync({type:"blob"})
        .then(function(content) {
          FileSaver.saveAs(content, "Locutores.zip");
        });
      } else {
        var filename = "_"
        var ws = XLSX.utils.json_to_sheet(filteredData.map(function(element){
          filename = element.voz + Math.random().toString(16).slice(2)
          return {
            Usuario: element.user.name + " " + element.user.surname,
            Título: element.title,
            Producto: products ? products.filter((prod) => prod.id == element.product_id)[0].name : "---",
            Locutor: element.voz,
            Idioma: element.idioma,
            Fecha_creación: new Date(element.created_at).toLocaleDateString('es'),
            Duración: (element.audio_length ? (Math.round((element.audio_length + Number.EPSILON) * 100) / 100) : 0) + "s",
            Tarifa: (element.invoice ? (Math.round(((element.invoice.price.cost) + Number.EPSILON) * 100) / 100) : 0) + "€/minuto",
            Total: (element.invoice ? (Math.round(((element.invoice.cost) + Number.EPSILON) * 100) / 100) : 0) + "€",
          }
        }));
        var wb = {Sheets: {'data': ws}, SheetNames: ['data']};
        var excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        var excelData = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
        FileSaver.saveAs(excelData, filename + '.xlsx');
      }
    }

    //------------------------------------------------------------------------------------

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <Card sx={{ height: "100%" }}>
          <SoftBox padding="1rem">
            <SoftBox mb={2}>
              <SoftTypography variant="h4" fontWeight="medium">
                {mode ? "Datos de locutores" : ("Histórico de voz para " + (user ? (user.name + " " + user.surname) : ""))}
              </SoftTypography>
              <SoftBox mb={2} display="flex" justifyContent="flex-end">
                <SoftSelect
                  value={{ value: graphYear, label: graphYear }}
                  onChange={handleGraphYearChange}
                  options={createGraphYearOptions()}  
                />
              </SoftBox>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  {user && (
                    <DefaultLineChart
                      title= "Histórico de minutos"
                      chart={minutesHistoryData}
                    />)
                  }
                </Grid>
                <Grid item xs={12} lg={6}>
                  {user && (
                    <DefaultLineChart
                      title= "Histórico de costes"
                      chart={priceHistoryData}
                    />)
                  }
                </Grid>
              </Grid>
              <SoftTypography mt={5} variant="h6" fontWeight="medium" textTransform="capitalize">
                Histórico de locuciones
              </SoftTypography> 
              {mode ? <ExportDataTable
                canSearch
                table={{
                  columns: mode ? columns : columns.slice(0, 3).concat(columns.slice(4)),
                  rows: [inferences?.map(createTableRow) ? inferences?.map(createTableRow).sort(function(a,b){return b.fecha_cre_entera - a.fecha_cre_entera;}) : []]
                }}
                filters={{
                  filterValue: speakerDefault,
                  filterOptions: createSpeakerOptions(),
                  filterExport: exportExcel,
                  filterColumn: "locutor",
                  filterDefaultValue: "Todos los locutores"
                }}
                export={exportExcel}
              /> : <DataTable
                canSearch
                table={{
                  columns: mode ? columns : columns.slice(0, 3).concat(columns.slice(4)),
                  rows: [inferences?.map(createTableRow) ? inferences?.map(createTableRow).sort(function(a,b){return b.fecha_cre_entera - a.fecha_cre_entera;}) : []]
                }}
              />}
             
            </SoftBox>
          </SoftBox>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

// MODE -> 0 = normal user view; 1 = superuser view
SpeakerHistory.defaultProps = {
  mode: 0,
};

// Typechecking props of the SoftAlert
SpeakerHistory.propTypes = {
  mode: PropTypes.number
};

export default SpeakerHistory;
